import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, debounceTime, filter, Observable } from 'rxjs';

import { DeviceInventoryLoadingStates } from '../api/inventory-devices-api.service';

export type InventoryLoadingInfo = {
	state: DeviceInventoryLoadingStates;
	totalDevices: number;
	numDevicesReceivedSnapshot: number;
};

@Injectable({
	providedIn: 'root'
})
export class InventoryLoadingStateService {
	private currentState = new BehaviorSubject<InventoryLoadingInfo>({
		state: 'WaitingToStart',
		totalDevices: 0,
		numDevicesReceivedSnapshot: 0
	});
	private allDevices = new Set<string>();
	private devicesReceivedSnapshot = new Set<string>();

	constructor() {
		// This is a 10sec popcorn timer to protect against being stuck in the "WaitingForSnapshots" state.
		this.currentState
			.asObservable()
			.pipe(
				filter((info) => info.state === 'WaitingForSnapshots'),
				debounceTime(10_000),
				takeUntilDestroyed()
			)
			.subscribe(() => {
				this.setState('Done');
			});
	}

	public inventoryLoadingState$(): Observable<InventoryLoadingInfo> {
		return this.currentState.asObservable();
	}

	public numDevicesReceivedSnapshot(): number {
		return this.devicesReceivedSnapshot.size;
	}

	public setState(newState: DeviceInventoryLoadingStates): void {
		this.currentState.next({
			state: newState,
			totalDevices: this.allDevices.size,
			numDevicesReceivedSnapshot: this.devicesReceivedSnapshot.size
		});
	}

	public trackDeviceAdded(id: string): void {
		this.allDevices.add(id);
	}

	public trackDeviceReceivedSnaqpshot(id: string): void {
		this.devicesReceivedSnapshot.add(id);
		this.checkIfDoneAndUpdate();
	}

	/**
	 * Removes entries for one or more devices from internal containers so they are no longer tracked
	 * Then, updates the loading state.
	 * @param id - the ID to untrack
	 */
	public untrack(id: string): void {
		this.allDevices.delete(id);
		this.devicesReceivedSnapshot.delete(id);
		this.checkIfDoneAndUpdate();
	}

	private checkIfDoneAndUpdate(): void {
		// we only need to perform these checks if we're in the 'WaitingForSnapshots' state
		if (this.currentState.value.state !== 'WaitingForSnapshots') {
			return;
		}

		// if the sizes of the two sets are the same, we're done.
		if (this.allDevices.size === this.devicesReceivedSnapshot.size) {
			this.setState('Done');
			return;
		}

		// else, we're still waiting for snapshots. need to reset/broadcast the
		// event to keep the popcorn timer "poppin"
		if (this.allDevices.size !== this.devicesReceivedSnapshot.size) {
			this.setState('WaitingForSnapshots');
		}
	}
}
