import { FirmwarePackage } from '@shure/shared/models';

import { PropertyPanelDeviceFragment } from '../graphql/generated/cloud-sys-api';

export function mapFirmwarePackagesFromSysApi(
	availablePkgs: PropertyPanelDeviceFragment['features']['availablePackages']
): FirmwarePackage[] {
	const array = Array.from(<FirmwarePackage[]>availablePkgs?.primaryPackages ?? []);

	const sortFn = (a: FirmwarePackage, b: FirmwarePackage): number => {
		// example: 12.34.56.7 -> 00012000340005600007
		const aVersion = a.version
			.split('.')
			.map((n) => n.toString().padStart(5, '0')) // max of 65535 per hextet
			.join('');

		const bVersion = b.version
			.split('.')
			.map((n) => n.toString().padStart(5, '0')) // max of 65535 per hextet
			.join('');

		if (aVersion > bVersion) {
			return -1;
		} else if (bVersion > aVersion) {
			return 1;
		}

		return 0;
	};

	return array.sort(sortFn);
}
