import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscriber,
	Subscription,
	from,
	merge,
	take,
	takeUntil,
	tap
} from 'rxjs';

import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceInventoryEvent, DeviceDiscoveryApiService } from '../api/device-discovery-api.service';

import {
	DiscoveredDevicesQueryGQL,
	DiscoveredDevicesQueryOpResult,
	DiscoveredDevicesSubscriptionGQL,
	DiscoveryDeviceFragment
} from './graphql/generated/cloud-sys-api';
import { SysApiDeviceInventoryApolloCache } from './sys-api-device-inventory-apollo-cache.service';
import { InventoryLoadingStateService } from './sys-api-device-inventory-loading-state.service';

export const DISCOVERED_DEVICES_QUERY_PAGE_SIZE = 1000;

interface DeviceDiscoveryEvent {
	added?: DiscoveryDeviceFragment;
	removed?: string;
}

@Injectable({ providedIn: 'root' })
export class SysApiDeviceDiscoveryApiService implements DeviceDiscoveryApiService {
	//
	private discoveredDevicesMap = new Map<string, DiscoveryDeviceFragment>();
	private discoveredDevicesInternal$ = new BehaviorSubject<DiscoveryDeviceFragment[]>([]);
	private _discoveryEvents$ = new Subject<DeviceDiscoveryEvent[]>();
	private paginatedQueryEndCursor$: Subject<string | null> | undefined = undefined;
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	constructor(
		logger: ILogger,
		private readonly discoveredDevicesQueryGQL: DiscoveredDevicesQueryGQL,
		private readonly discoveredDevicesSubscriptionGQL: DiscoveredDevicesSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		private readonly deviceApolloCache: SysApiDeviceInventoryApolloCache,
		private readonly inventoryLoadingStateService: InventoryLoadingStateService
	) {
		this.logger = logger.createScopedLogger('DeviceDiscoveryService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public getDiscoveredDevicesByQuery$<ElementType extends { id: string }>(
		elementQueryFunction: (id: string) => Observable<ElementType>,
		_elementFilterFunction?: (element: ElementType) => boolean
	): Observable<DeviceInventoryEvent<ElementType>> {
		return new Observable((observer: Subscriber<DeviceInventoryEvent<ElementType>>) => {
			observer.next({ allDevices: [], change: undefined, numDevicesReceiviedSnapshot: 0 });
			try {
				const cachedDevicesMap = new Map<string, ElementType>();
				const queryHandlers = new Map<string, Subscription>();
				this.discoveryEvents$().subscribe((events) => {
					events.forEach((e) => {
						if ('added' in e && e.added?.id) {
							const queryHandler = elementQueryFunction(e.added?.id)
								.pipe(
									tap((d) => {
										const eventType = cachedDevicesMap.has(d.id) ? 'updated' : 'added';
										cachedDevicesMap.set(d.id, d);
										observer.next({
											allDevices: [...cachedDevicesMap.values()],
											change: {
												type: eventType,
												device: d
											},
											numDevicesReceiviedSnapshot:
												this.inventoryLoadingStateService.numDevicesReceivedSnapshot()
										});
									})
								)
								.subscribe();
							queryHandlers.set(e.added?.id, queryHandler);
						} else if ('removed' in e && e.removed) {
							const queryHandler = queryHandlers.get(e.removed);
							if (queryHandler) {
								queryHandler.unsubscribe();
								queryHandlers.delete(e.removed);
							}

							const foundDevice = cachedDevicesMap.get(e.removed);
							if (foundDevice) {
								cachedDevicesMap.delete(e.removed);
								observer.next({
									allDevices: [...cachedDevicesMap.values()],
									change: {
										type: 'removed',
										device: foundDevice
									},
									numDevicesReceiviedSnapshot:
										this.inventoryLoadingStateService.numDevicesReceivedSnapshot()
								});
								//
								// remove from apollo cache
								this.deviceApolloCache.removeEntry(e.removed);
							}
						}
					});
				});

				// Add a finalizer method to the observer... this is to automatically unsbubscribe from
				// all queries when/if no one is subscribed to the Observable.
				observer.add(() => {
					queryHandlers.forEach((queryHandler) => queryHandler.unsubscribe());
				});
			} catch {
				observer.error();
			}
		});
	}

	private discoveryEvents$(): Observable<DeviceDiscoveryEvent[]> {
		return merge(
			this._discoveryEvents$,
			from([...this.discoveredDevicesMap.values()].map((device) => [{ added: device }]))
		);
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializing service');

		// we need a new destroy$ subject since it is "completed" when the user logs out.
		this.destroy$ = new Subject();

		this.discoveredDevicesMap.clear();
		this.inventoryLoadingStateService.setState('Discovering');
		this.emitDiscoveredDevices();
		this.subscribeDiscoveredDevices();

		this.paginatedQueryEndCursor$ = new Subject<string | null>();
		this.paginatedQueryEndCursor$.subscribe({
			next: (endCursor) => {
				this.queryDiscoveredDevicesNetwork(endCursor);
			}
		});
		this.paginatedQueryEndCursor$.next(null); // trigger the initial query;
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.discoveredDevicesMap.clear();
		this.emitDiscoveredDevices();
		this.destroy$.next();
		this.destroy$.complete();
		this.paginatedQueryEndCursor$?.complete();
		this.inventoryLoadingStateService.setState('WaitingToStart');
	}

	private handleDevicesAdded(devices: DiscoveryDeviceFragment[]): void {
		const events: DeviceDiscoveryEvent[] = [];
		devices.forEach((device) => {
			this.inventoryLoadingStateService.trackDeviceAdded(device.id);
			this.deviceApolloCache.seedEntry(device);
			this.discoveredDevicesMap.set(device.id, device);
			events.push({ added: device });
		});

		this._discoveryEvents$.next(events);
		this.emitDiscoveredDevices();
	}

	private handleDeviceRemoved(id: string): void {
		this.logger.information('handleDeviceRemoved', '', { id });

		this._discoveryEvents$.next([{ removed: id }]);
		this.discoveredDevicesMap.delete(id);
		this.inventoryLoadingStateService.untrack(id);
		this.emitDiscoveredDevices();
	}

	private emitDiscoveredDevices(): void {
		this.discoveredDevicesInternal$.next([...this.discoveredDevicesMap.values()]);
	}

	private subscribeDiscoveredDevices(): void {
		this.discoveredDevicesSubscriptionGQL
			.subscribe(
				{},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  always fetch from network, then store in cache
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					if (change.data) {
						if ('added' in change.data.discoveredDevices) {
							this.handleDevicesAdded([change.data.discoveredDevices.added]);
						} else if ('removed' in change.data.discoveredDevices) {
							this.handleDeviceRemoved(change.data.discoveredDevices.removed);
						}
					}
				},

				complete: () => {
					this.logger.warning('subscribeDiscoveredDevices', 'completed');
				},

				error: (err) => {
					setTimeout(() => {
						this.logger.error('subscribeDiscoveredDevices', 'error', JSON.stringify({ err }));
						this.subscribeDiscoveredDevices();
					}, 10000);
				}
			});
	}

	private queryDiscoveredDevicesNetwork(afterCursor: string | null): void {
		this.discoveredDevicesQueryGQL
			.fetch(
				{
					first: DISCOVERED_DEVICES_QUERY_PAGE_SIZE,
					after: afterCursor,
					deviceModels: []
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(
				take(1),
				tap((devices) => this.processDiscoveredDevicesQueryResult(devices.data))
			)
			.subscribe();
	}

	private processDiscoveredDevicesQueryResult(devices: DiscoveredDevicesQueryOpResult): void {
		const { hasNextPage, endCursor } = devices.discoveredDevicesConnection.pageInfo;

		const addedDevices: DiscoveryDeviceFragment[] = [];
		devices.discoveredDevicesConnection.edges.forEach((edge) => {
			if (edge.node) {
				addedDevices.push(edge.node);
			}
		});
		this.handleDevicesAdded(addedDevices);

		// if there's more data on the server, trigger the next query.
		if (hasNextPage && endCursor && endCursor.length !== 0) {
			this.paginatedQueryEndCursor$?.next(endCursor);
			return;
		}

		// we're done discoverying... either transition to the appropriate next
		// state depending on how many devices discovered
		const numDevicesDiscovered = this.discoveredDevicesMap.size + devices.discoveredDevicesConnection.edges.length;
		if (numDevicesDiscovered === 0) {
			// no devices, go right to 'Done'
			this.inventoryLoadingStateService.setState('Done');
		} else {
			this.inventoryLoadingStateService.setState('WaitingForSnapshots');
		}
	}
}
