import { Injectable } from '@angular/core';
import { StoreObject, TypePolicies, TypePolicy } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';

import { ApolloTypePolicyProvider } from '../apollo-type-policy.provider';

const mergePolicyNoKey: TypePolicy = {
	keyFields: false,
	merge: true
};

// function/concept copied from DNG code.
const deepMergePolicyNoKey: TypePolicy = {
	keyFields: false,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	merge: (existing, incoming): any => {
		return mergeDeep(existing, incoming);
	}
};

const mergePolicyNoKeyKeepTypename: TypePolicy = {
	keyFields: false,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	merge: (existing, incoming): any => {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const __typename = existing ? existing.__typename : incoming.__typename;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		return { ...existing, ...incoming, __typename };
	}
};

const mergePolicyFixedTypename = (typename: string): TypePolicy => {
	return {
		merge: true,
		fields: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			__typename: {
				// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
				merge(_existing, _incoming) {
					return typename;
				}
			}
		}
	};
};

@Injectable({
	providedIn: 'root'
})
export class SysApiTypePolicyProvider implements ApolloTypePolicyProvider {
	// eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
	typenameMap(): { [__typename: string]: (storeObject: Readonly<StoreObject>) => string } {
		return {
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			DeviceChange: (storeObject) => `Device:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			RoomChange: (storeObject) => `Room:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			AudioChannelChange: (storeObject) => `AudioChannel:${storeObject['id']}`,
			// eslint-disable-next-line @typescript-eslint/naming-convention, dot-notation
			DeviceStatusChange: (storeObject) => `Device:${storeObject['id']}`
		};
	}

	public typePolicies(): TypePolicies {
		return {
			RoomChange: mergePolicyFixedTypename('Room'),

			// Device
			DeviceChange: mergePolicyFixedTypename('Device'),
			DeviceStatusChange: mergePolicyFixedTypename('Device'),

			// First level in device which is not normalized (no id), need deep merge
			DeviceFeatures: deepMergePolicyNoKey,
			DeviceDescription: deepMergePolicyNoKey,
			DeviceSupportedFeatures: mergePolicyNoKey,

			DeviceAllEqualizersBypassChange: mergePolicyNoKeyKeepTypename,
			DeviceAllIntellimixBypassChange: mergePolicyNoKeyKeepTypename,
			DeviceAudioChannelCountChange: mergePolicyNoKeyKeepTypename,
			DeviceDanteAudioEncryptionChange: mergePolicyNoKeyKeepTypename,
			DeviceAuthenticationChange: mergePolicyNoKeyKeepTypename,
			DeviceAutomixGainMeteringChange: mergePolicyNoKeyKeepTypename,
			DeviceAvailablePackagesChange: mergePolicyNoKeyKeepTypename,
			DeviceBatteryLevelChange: mergePolicyNoKeyKeepTypename,
			DeviceCallStatusChange: mergePolicyNoKeyKeepTypename,
			DeviceDanteAudioNetworkChange: mergePolicyNoKeyKeepTypename,
			DeviceDanteAudioNetworkAccessChange: mergePolicyNoKeyKeepTypename,
			DeviceEqualizerContourChange: mergePolicyNoKeyKeepTypename,
			DeviceEthernetLightsChange: mergePolicyNoKeyKeepTypename,
			DeviceIdentifyChange: mergePolicyNoKeyKeepTypename,
			DeviceMeteringModesChange: mergePolicyNoKeyKeepTypename,
			DeviceAudioMuteChange: mergePolicyNoKeyKeepTypename,
			DeviceMicStatusChange: mergePolicyNoKeyKeepTypename,
			DeviceNameChange: mergePolicyNoKeyKeepTypename,
			DeviceProxiedDevicesChange: mergePolicyNoKeyKeepTypename,
			DeviceUpdateProgressChange: mergePolicyNoKeyKeepTypename,
			DeviceUptimeChange: mergePolicyNoKeyKeepTypename,
			DeviceUserPresetsChange: mergePolicyNoKeyKeepTypename,
			DeviceCoveragePositionChange: mergePolicyNoKeyKeepTypename,
			DeviceRoutingPositionChange: mergePolicyNoKeyKeepTypename,
			DeviceLocalLicenseChange: mergePolicyNoKeyKeepTypename,
			DeviceServerLicenseChange: mergePolicyNoKeyKeepTypename,
			DeviceLicenseChange: mergePolicyNoKeyKeepTypename,
			DeviceLicenseChangeV2: mergePolicyNoKeyKeepTypename,
			DeviceLicenseChannelCountChange: mergePolicyNoKeyKeepTypename,
			DeviceCoverageModeChange: mergePolicyNoKeyKeepTypename,
			DeviceRotationChange: mergePolicyNoKeyKeepTypename,
			DeviceHeightChange: mergePolicyNoKeyKeepTypename,
			DeviceAudioMuteControlGroupChange: mergePolicyNoKeyKeepTypename,
			DeviceRoomChange: mergePolicyNoKeyKeepTypename,
			DeviceTagsChange: mergePolicyNoKeyKeepTypename,

			// AudioChannel
			AudioChannelChange: mergePolicyFixedTypename('AudioChannel'),
			AudioChannelDescription: mergePolicyNoKey,
			AudioChannelSupportedFeatures: mergePolicyNoKey,
			AudioChannelFeatures: mergePolicyNoKey,

			AudioChannelAcousticEchoCancellationChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAdvancedAutomixerChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAecReferenceChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAnalogInputChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAnalogOutputChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAutoFocusChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAutoLevelChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAutomaticGainControlChange: mergePolicyNoKeyKeepTypename,
			AudioChannelAutomixerChange: mergePolicyNoKeyKeepTypename,
			AudioChannelCompressorChange: mergePolicyNoKeyKeepTypename,
			AudioChannelDelayChange: mergePolicyNoKeyKeepTypename,
			AudioChannelMonitoringChange: mergePolicyNoKeyKeepTypename,
			AudioChannelDirectOutputChange: mergePolicyNoKeyKeepTypename,
			AudioChannelDanteEncryptionStatusChange: mergePolicyNoKeyKeepTypename,
			AudioChannelFaderGroupChange: mergePolicyNoKeyKeepTypename,
			AudioChannelGainChange: mergePolicyNoKeyKeepTypename,
			AudioChannelGateChange: mergePolicyNoKeyKeepTypename,
			AudioChannelIntellimixChange: mergePolicyNoKeyKeepTypename,
			AudioChannelLimiterChange: mergePolicyNoKeyKeepTypename,
			AudioChannelLobeChange: mergePolicyNoKeyKeepTypename,
			AudioChannelLogicChange: mergePolicyNoKeyKeepTypename,
			AudioChannelMixBusChange: mergePolicyNoKeyKeepTypename,
			AudioChannelMuteChange: mergePolicyNoKeyKeepTypename,
			AudioChannelMuteGroupChange: mergePolicyNoKeyKeepTypename,
			AudioChannelNameChange: mergePolicyNoKeyKeepTypename,
			AudioChannelNoiseReductionChange: mergePolicyNoKeyKeepTypename,
			AudioChannelPcInputChange: mergePolicyNoKeyKeepTypename,
			AudioChannelPcOutputChange: mergePolicyNoKeyKeepTypename,
			AudioChannelDanteRouteReceiverChange: mergePolicyNoKeyKeepTypename,
			AudioChannelDanteRouteTransmitterChange: mergePolicyNoKeyKeepTypename,
			AudioChannelSoloChange: mergePolicyNoKeyKeepTypename,
			AudioChannelSoloMixChange: mergePolicyNoKeyKeepTypename,
			AudioChannelThresholdLimiterChange: mergePolicyNoKeyKeepTypename,
			AudioChannelTalkerHeightChange: mergePolicyNoKeyKeepTypename,

			// EqualizerFilter
			EqualizerFilterChange: mergePolicyNoKeyKeepTypename,

			Subscription: {
				fields: {
					discoveredDevices: {
						// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
						merge(_e, i) {
							return i;
						}
					}
				}
			},
			Query: {
				fields: {
					discoveredDevices: {
						// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
						merge(_e, i) {
							return i;
						}
					}
				}
			}
		};
	}
}
