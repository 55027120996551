import { Injectable, Inject, signal } from '@angular/core';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { isEqual } from 'lodash-es';
import { map, Observable, tap, Subscription, takeUntil, Subject, BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { DeviceRelationshipService } from '@shure/cloud/device-management/shared/services';
import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/cloud/shared/apollo';
import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ApolloQueryErrorMapper } from '@shure/shared/angular/data-access/system-api/core';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';
import { DeviceDiscoveryApiService, DeviceInventoryEvent } from '../api/device-discovery-api.service';
import { DeviceStatusService } from '../api/device-status.service';
import { DeviceInventoryLoadingStates, InventoryDevicesApiService } from '../api/inventory-devices-api.service';

import {
	NodeChangeType,
	InventoryDeviceByIdQueryGQL,
	InventoryDeviceSubscriptionGQL,
	InventoryDeviceFragment
} from './graphql/generated/cloud-sys-api';
import { mapInventoryDeviceFromSysApi } from './mappers/map-inventory-device';
import { InventoryLoadingStateService } from './sys-api-device-inventory-loading-state.service';

export type InventoryLoadingState = {
	state: DeviceInventoryLoadingStates;
	percentComplete: number;
};

@Injectable({ providedIn: 'root' })
export class SysApiInventoryDevicesApiService implements InventoryDevicesApiService {
	public deviceInventory = new BehaviorSubject<InventoryDevice[]>([]);
	public deviceInventoryEvents = new Subject<DeviceInventoryEvent<InventoryDevice>['change']>();
	public deviceInventorySnapshotCounts = new BehaviorSubject<number>(0);

	public inventoryLoadingState = signal<InventoryLoadingState>({
		state: 'WaitingToStart',
		percentComplete: 0
	});

	protected readonly logger: ILogger;

	private destroy$ = new Subject<void>();

	private readonly devicesSubscriptionManager = new SubscriptionManager({
		subscriptionType: 'inventory-devices',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	private inventoryQueryFetchPolicy: WatchQueryFetchPolicy = 'cache-only';

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceApiService,
		private readonly inventoryDeviceSubscriptionGQL: InventoryDeviceSubscriptionGQL,
		private readonly inventoryDeviceByIdQueryGQL: InventoryDeviceByIdQueryGQL,
		private readonly deviceDiscoveryService: DeviceDiscoveryApiService,
		private readonly deviceStatusService: DeviceStatusService,
		private readonly oktaService: OktaInterfaceService,
		private readonly deviceRelationshipService: DeviceRelationshipService,
		private readonly inventoryLoadingStateService: InventoryLoadingStateService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DaiInventoryDevicesService');
		this.inventoryQueryFetchPolicy = this.getFetchPolicyForInventoryDiscovery();

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});

		this.inventoryLoadingStateService
			.inventoryLoadingState$()
			.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
			.subscribe((info) => {
				this.inventoryLoadingState.update(() => {
					const newState = {
						state: info.state,
						percentComplete:
							info.totalDevices <= 0
								? 0
								: Math.floor((100 * info.numDevicesReceivedSnapshot) / info.totalDevices)
					};
					this.logger.information('LoadingState progress.', '%o', newState);
					return newState;
				});
			});
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(): Observable<InventoryDevice[]> {
		return this.deviceInventory.asObservable();
	}

	public getInventoryDeviceEvents$(): Observable<DeviceInventoryEvent<InventoryDevice>['change']> {
		return this.deviceInventoryEvents.asObservable();
	}

	public getInventoryDeviceSnapshotCount$(): Observable<number | undefined> {
		return this.deviceInventorySnapshotCounts.asObservable();
	}

	public getInventoryDevice$(deviceId: string, fetchPolicy: WatchQueryFetchPolicy): Observable<InventoryDevice> {
		return this.inventoryDeviceByIdQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestTags: this.appEnv.cdmFeatureFlags?.showTags ?? false,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? false
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy,
					returnPartialData: true
				}
			)
			.valueChanges.pipe(
				map((query) => {
					const device = query.data.node;
					if (device && 'isDevice' in device) {
						if (this.enoughFeaturesForView(device.features)) {
							this.inventoryLoadingStateService.trackDeviceReceivedSnaqpshot(device.id);
						}
						return mapInventoryDeviceFromSysApi(device, this.deviceStatusService);
					}
					throw ApolloQueryErrorMapper.getError(query);
				})
			);
	}

	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', { deviceId, name });
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();

		this.getInventoryDeviceEvents$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((event) => {
				if (event && (event.type === 'added' || event.type === 'updated')) {
					this.deviceRelationshipService.registerProxiedDevices(event.device);
					this.devicesSubscriptionManager.register([event.device.id]);
				} else if (event?.type === 'removed' && event.device.id) {
					this.devicesSubscriptionManager.deregister(event?.device.id);
					this.deviceRelationshipService.removeDevice(event?.device.id);
				}
			});

		this.getDeviceInventoryEvents();
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesSubscriptionManager.deregisterAll();
	}

	private getDeviceInventoryEvents(): void {
		this.deviceDiscoveryService
			.getDiscoveredDevicesByQuery$<InventoryDevice>(
				(id) => this.getInventoryDevice$(id, this.inventoryQueryFetchPolicy).pipe(takeUntil(this.destroy$)),
				(_device) => true // filter function (don't remove any)
			)
			.pipe(
				tap((event) => {
					this.deviceInventory.next(event.allDevices);
					this.deviceInventoryEvents.next(event.change);
					this.deviceInventorySnapshotCounts.next(event.numDevicesReceiviedSnapshot);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private createDeviceSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [
			NodeChangeType.DeviceAvailablePackages,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceControlNetwork,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceLicenseV3,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceName,
			NodeChangeType.DeviceProxiedDevices,
			NodeChangeType.DeviceUpdateProgress,
			NodeChangeType.DeviceRoom,
			NodeChangeType.DeviceTags
		];

		return this.inventoryDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestTags: this.appEnv.cdmFeatureFlags?.showTags ?? false,
					requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? false
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  we always want subscription data from the server
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data) => {
					if (data.data?.nodeChanges && 'features' in data.data.nodeChanges) {
						const features = <InventoryDeviceFragment['features']>data.data.nodeChanges.features;
						if (this.enoughFeaturesForView(features)) {
							this.inventoryLoadingStateService.trackDeviceReceivedSnaqpshot(id);
						}
					}
				},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				complete: () => {},
				error: (error) => {
					this.logger.error(
						'inventoryDeviceSubscriptionGQL',
						'Encountered error',
						JSON.stringify({ id, error })
					);
					retryCallback();
				}
			});
	}
	private getFetchPolicyForInventoryDiscovery(): WatchQueryFetchPolicy {
		/**
		 * cache-first: return result from cache. Only fetch from network if cached result is not available.
		 * cache-and-network: return result from cache first (if it exists), then return network result once it's available.
		 * cache-only: return result from cache if available, fail otherwise.
		 * no-cache: return result from network, fail if network call doesn't succeed, don't save to cache
		 * network-only: return result from network, fail if network call doesn't succeed, save to cache
		 */
		const retVal: WatchQueryFetchPolicy = 'network-only';

		const lsItem = window.localStorage.getItem('shure-cdm-inv-query-fetchpolicy');
		if (lsItem === undefined) {
			return retVal;
		}

		switch (lsItem) {
			case 'cache-first':
			case 'cache-and-network':
			case 'cache-only':
			case 'no-cache':
			case 'network-only':
				this.logger.debug('fetchPolictyForInventoryDiscovery', 'localStorage Value: %s', lsItem);
				return lsItem;
			default:
				this.logger.error(
					'fetchPolictyForInventoryDiscovery',
					`Invalid value ${lsItem} in localStorage. Using: ${retVal}`
				);
				return retVal;
		}
	}

	private enoughFeaturesForView(features: InventoryDeviceFragment['features']): boolean {
		// count how many features have non-null values.
		const numNonNullFeatures = Object.entries(features).reduce(
			(count, [key, value]) => (key === '__typename' || value === null ? count : ++count),
			0
		);
		return numNonNullFeatures > 0;
	}
}
