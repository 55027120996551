/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { mergeDeep } from '@apollo/client/utilities';
import { asyncScheduler } from 'rxjs';

import { ApolloCacheFactory, ApolloCacheManager } from '@shure/cloud/shared/apollo';
import { CompatibilityLevel } from '@shure/shared/angular/data-access/system-api/core';

import {
	DiscoveryDeviceFragment,
	InventoryDeviceByIdQueryDocument,
	InventoryDeviceByIdQueryVariables,
	InventoryDeviceFragment
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceInventoryApolloCache {
	constructor(
		private readonly apolloCacheManager: ApolloCacheManager,
		private readonly apolloCache: ApolloCacheFactory
	) {}

	public seedEntry(device: DiscoveryDeviceFragment): void {
		const variables: InventoryDeviceByIdQueryVariables = {
			nodeId: device.id,
			requestTags: true,
			requestLicenseV3: true
		};
		this.apolloCache.cache?.writeQuery({
			query: InventoryDeviceByIdQueryDocument,
			data: { node: mergeDeep(this.makeStubInventoryDeviceFragmemnt(device)) },
			variables,
			broadcast: false, // significant perf cost if this is TRUE.
			overwrite: true
		});
	}

	public removeEntry(id: string): void {
		// Schedule the cache removal for a short amount of time in the future
		// This allows any watchQueries to get removed w/out reporting errors.
		asyncScheduler.schedule(
			() => this.apolloCacheManager.getApolloCache('sysApi')?.evict({ id: `Device:${id}`, broadcast: false }),
			1000
		);
	}

	private makeStubInventoryDeviceFragmemnt(device: DiscoveryDeviceFragment): InventoryDeviceFragment {
		return mergeDeep(
			{
				hardwareId: '',
				virtual: false,
				compatibility: CompatibilityLevel.CompatibleOld,
				description: {
					__typename: 'DeviceDescription',
					features: {
						__typename: 'DeviceSupportedFeatures',
						audioMute: { supported: false },
						audioNetwork: { supported: false },
						availablePackages: { supported: false },
						batteryLevel: { supported: false },
						controlNetwork: { supported: false },
						danteAudioNetwork: { supported: false },
						firmware: { supported: false },
						identify: { supported: false },
						micStatus: { supported: false },
						name: { supported: false },
						proxiedDevices: { supported: false },
						rfChannels: { supported: false },
						serialNumber: { supported: false },
						uptime: { supported: false },
						updateProgress: { supported: false },
						room: { supported: false },
						tags: { supported: false },
						licenseV3: { supported: false }
					}
				},
				features: {
					__typename: 'DeviceFeatures',
					availablePackages: null,
					batteryLevel: null,
					controlNetwork: null,
					firmware: null,
					identify: null,
					micStatus: null,
					name: null,
					proxiedDevices: null,
					room: null,
					serialNumber: null,
					tags: null,
					updateProgress: null,
					licenseV3: null
				},
				protocol: {
					__typename: 'CommunicationProtocol',
					dmp: null
				},
				...device
			},
			{}
		);
	}
}
